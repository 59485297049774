import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { GlobalStyles } from '~styles/GlobalStyles';
import { contentLayoutMixin } from '~styles/mixins';
import { ThemeContextProvider } from '~contexts/ThemeContextProvider';
import SEO from '~components/SEO/SEO';

const Main = styled.main`
  ${contentLayoutMixin};
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const H1 = styled.h1`
  font-size: 1.9rem;
  font-weight: 300;
  text-indent: .4rem;
  letter-spacing: .56rem;
  text-transform: uppercase;
  margin: .5rem 0 3rem 0;
`

const Brand = styled(Link)`
  font-size: 3rem;
  font-weight: 200;
  line-height: 3.7rem;
  text-transform: uppercase;
  color: inherit;
  display: block;

  span {
    font-size: 5rem;
    font-weight: 500;
    display: block;
  }
`

const Back = styled(Link)`
  color: inherit;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotFoundPage = () => {

  const data = useStaticQuery(graphql`
    query {
      section: sectionYaml(title: {eq: "Page Not Found"}) {
        ...NotFoundSection
      }
    }
  `);

  return (
    <ThemeContextProvider name="brown">
      <GlobalStyles />
      <SEO title={data.section.title} />
      <Main>
        <div>
          <Brand to="/" title="Home">
            Jon <span>Wenmoth</span>
          </Brand>
          <H1>{data.section.title}</H1>
          <Back to="/" title="Back home">
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" fill="rgb(0,0,0)"><rect fill="none" height="24" width="24" /><g><polygon points="17.77,3.77 16,2 6,12 16,22 17.77,20.23 9.54,12" /></g></svg> Home
          </Back>
        </div>
      </Main>
    </ThemeContextProvider>
  )
}

export default NotFoundPage;
